import React from 'react'

export default function ChooseUs() {
    return (
        <div className='container-fluid ChooseUs fade-in'>
            <div className='row'>
                <h1 className='ChooseUsHeading'>Why To Choose Us?</h1>
                <div className="ChooseUsContent">
                    <div className="section1 ChooseUsSection">
                        <i className="fa-solid fa-award"></i>
                        <h3>Quality</h3>
                        <h5>At our core, we are proud to be able to offer the best quality of food at the most reasonable price. Our team of experts is constantly working to improve our quality.</h5>
                    </div>
                    <div className="section2 ChooseUsSection">
                        <i className="fa-solid fa-truck"></i>
                        <h3>Delivery</h3>
                        <h5>We offer free delivery on orders above INR 500. We are continuously working to improve our delivery service.</h5>
                    </div>
                    <div className="section3 ChooseUsSection">
                        <i className="fa-solid fa-cookie-bite"></i>
                        <h3>Confectionery Work</h3>
                        <h5>Our confectionery work is of the highest quality. We are constantly working to improve our confectionery work.</h5>
                    </div>
                    <div className="section4 ChooseUsSection">
                        <i className="fa-solid fa-gifts"></i>
                        <h3>Gifts & Offers</h3>
                        <h5>Gift the joy of indulgence with our delightful treats. Explore our tempting offers and make every occasion a celebration of sweetness.</h5>
                    </div>
                </div>
            </div>
        </div>
    )
}
